import React from "react";
import styles from "./ImageGridB.module.css";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";
import { alignmentMapping } from "@/staticData/constant";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import FlexGrid from "@/mvcComponents/Commons/FlexGrid/FlexGrid.view";
import OnlyMediaController from "../OnlyMedia/OnlyMedia.controller";
import UseComponentVisibility from "@/hooks/useComponentVisibility";

const ImageGridB = ({
  heading = "",
  subHeading = "",
  ctaText = "",
  ctaLink = "",
  section_id = "",
  listData = [],
  description = "",
  alignment = "left",
  columns = 1,
  gap = "6px",
  height = 200,
  width = 600,
  showOn = [],
}) => {
  return (
    <UseComponentVisibility conditions={showOn}>
      <div
        className={styles.root}
        id={section_id}
        style={{ alignItems: alignmentMapping[alignment] }}
        view-analytics={[UIElements.CARD, `listing-c-${section_id}`]}
      >
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        {subHeading && (
          <p
            className={styles.subHeading}
            style={{ textAlign: alignmentMapping[alignment] }}
            dangerouslySetInnerHTML={{ __html: subHeading }}
          />
        )}
        <FlexGrid
          alignment={alignment}
          listData={listData}
          columns={columns}
          component={OnlyMediaController}
          gap={gap}
          name={section_id}
          customCardData={{
            styleOverride: {
              height,
              width,
              objectPosition: "center",
              borderRadius: "8px",
              objectFit: "contain",
              backgroundColor: "#000",
            },
          }}
        />
        {description && (
          <p
            className={styles.description}
            style={{ textAlign: alignmentMapping[alignment] }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {ctaLink && ctaText && (
          <ConditionLinkView link={ctaLink} section_id={section_id}>
            <div className={styles.cta}>{ctaText}</div>
          </ConditionLinkView>
        )}
      </div>
    </UseComponentVisibility>
  );
};

export default ImageGridB;
